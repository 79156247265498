export const login = (state = { userRole: "visitor", accessToken: null }, action) => {
  switch (action.type) {
    case "LOGOUT_WITH_API": {
      return { ...state, userRole: "visitor", accessToken: null }
    }
    case "LOGIN_WITH_API": {
      return { ...state, userRole: action.payload.userRole, accessToken: action.payload.accessToken, username: action.payload.username }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.payload }
    }
    default: {
      return state
    }
  }
}
