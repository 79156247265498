import axios from "../../../utility/api"

const orderDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    createdAt: item ? item.createdAt : "",
    createdBy: item ? item.createdBy : "",
    deliveryType: item ? item.deliveryType : "",
    packageId: item ? item.packageId : "",
    recipientEmail: item ? item.recipientEmail : "",
    recipientName: item ? item.recipientName : "",
    recipientNumber: item ? item.recipientNumber : "",
    recipientPhone: item ? item.recipientPhone : "",
    state: item ? item.state : "",
    assignedLocker: (item && item.assignedLocker) ? item.assignedLocker : [],
    logs: (item && item.logs) ? item.logs : [],
    pickUpAt: item ? item.pickUpAt : "",
    storedAt: item ? item.storedAt : "",
  }
}

export const getOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=1,2,3,4,5,6,7,8,9,10,11,12,13&archived=false')
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getDepositedOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=4&archived=false')
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getArchivedOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ARCHIVED_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=14&archived=false')
    .then(response => {
      if (response.data.ResultCode===1) {
        let archivedOrdersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ARCHIVED_ORDERS_LIST_SUCCESS",
          payload: {archivedOrders: archivedOrdersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
})

export const getOrdersHistory = (token, max_results,/*page, rowsPerPage, filters,*/ reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&archived=true')
    .then(response => {
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getOrderDetails = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: false
      });
    }

    if (id==="new") {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: orderDetails()
      });
      return;
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/order?packageId='+id).then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        if(res.data.Data.state !== 1 && res.data.Data.state !== 2){
          resolve('orderDelivered')
        }else{
          resolve()
        }
      } else {
        reject('orderNotExist')
      }
    }).catch(err => {
      reject()
    })
})

export const setOrderDetails = (token,order) => (dispatch) =>
new Promise(function(resolve, reject){
  console.log(order)
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  if (order.id === 0) {
    axios.post('api/delivery/order', {
      packageId: order.packageId,
      recipientName: 'MANUAL',
      recipientNumber: order.recipientNumber,
      recipientEmail: order.recipientEmail,
      recipientPhone: order.recipientPhone
    })
    .then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject(err)
    })
  } else {
    axios.put('api/delivery/order', {
      id: order.id,
      packageId: order.packageId,
      recipientName: order.recipientName,
      recipientNumber: order.recipientNumber,
      recipientEmail: order.recipientEmail,
      recipientPhone: order.recipientPhone
    })
    .then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
  }
})

export const assignLocker = (token, locationId, packageId, size, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: false
      });
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/assignLocker?locationId='+locationId+'&size='+size+'&packageId='+packageId).then(res => {
      if (res.data.ResultCode && res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      }else if(res.data.Description && res.data.Description === 'No locker avaliable'){
        reject('No locker available')
      }else {
        reject()
      }
    }).catch(err => {
      reject()
    })
})

export const getLockers = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
      if (reset) {
        dispatch({
          type: "GET_ORDER_PLACES_LOCKERS",
          payload: false
        })
      }

      axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
      axios.get('api/julian/lockers')
      .then(res => {
        if (res.data.ResultCode===1) {
          let lockers = res.data.Data && Object.keys(res.data.Data).reduce(function (r, a) {
            r[a] = res.data.Data[a]
            return r
          }, {})

          dispatch({
            type: "GET_ORDER_PLACES_LOCKERS",
            payload: lockers
          })
          resolve()
        }else{
          reject()
          throw(res)
        }
      }).catch(err => {
        reject()
      })
})

export const deleteOrder = (token, order) => (dispatch) =>
new Promise(function(resolve, reject) {
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.delete('api/delivery/order', {data: { packageId: order.packageId }}).then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
      console.log(err.response)
    })
})
