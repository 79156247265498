const projectReducer = (state = { companyList : [], selectedCompany: null }, action) => {
  switch (action.type) {
    case "GET_COMPANY_SUCCESS":
      return { ...state, companyList: action.payload }
    case "GET_COMPANY_DETAILS_SUCCESS":
    console.log(action.payload)
      return { ...state, selectedCompany: action.payload }
    default:
      return state
  }
}

export default projectReducer
