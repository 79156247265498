const expiredLockersReducer = (state = { expiredLockersList : null }, action) => {
  switch (action.type) {
    case "GET_EXPIRED_LOCKERS_SUCCESS":
      return { ...state, expiredLockersList: action.payload }
    default:
      return state
  }
}

export default expiredLockersReducer
